<template>
  <div class="about">
    <Header @screewidth="screewidthchange"></Header>
    <div class="header"></div>
    <div class="showlist" ref="showlist">
      <div class="showlist_in">
        <div class="cont_l_bot" v-if="lists.player!==''&&lists.player!==undefined">
          <a
            class="botr_sub"
            href="javascript:void(0)"
            @click="playvideoPlayer"
          >
            <i class="fas fa-play"></i>
          </a>
        </div>
        <div class="in_left" :class="showin?'in_lefts':''">
          <span></span>

          <h2>{{ lists.title }}</h2>

          <p  v-html="lists.content">
           
          </p>
          <!-- <ul>
            <li>
              <i class="ai"></i>
              <h3>Digital Data Analysis</h3>
              <span
                >We provide marketing service to startups businesses to looking
                for a partner digital media.</span
              >
            </li>
            <li>
              <i class="ai"></i>
              <h3>Marketing Automation</h3>
              <span
                >We provide marketing service to startups businesses to looking
                for a partner digital media.</span
              >
            </li>
          </ul> -->
        </div>
        <div class="in_right"  :class="showin?'in_rights':''">
          <img :src="lists.image" alt="" />
        </div>
      </div>
    </div>
    <div class="apdfdiv">
       <div class="div_top"><h1>资料</h1> </div>
      <Pdf :pdf-url="lists.pdfurl"></Pdf>
    </div>
    <div class="case" v-if="lists.case==undefined?false:true">
      <div class="div_top"><h1>案例</h1></div>
      <div class="case_img">
        <viewer class="viewer" :images="lists.case"> 
         <img v-for="(item,index) in lists.case" :key="index" :src="item" alt="">
        </viewer>
      </div>
    </div>
    <Bottom></Bottom>
    <div class="videoplayer" v-if="videolpayerdialog">
      <Player class="player" :player-url="playerurl"></Player>
      <button class="playerbut" @click="playerClick">×</button>
    </div>
  </div>
</template>
<script>
import Header from "@/components/Header";
import Bottom from "@/components/Bottom.vue";
// const Pdf = resolve => import('@/components/Pdf').then(m => resolve(m))
import Pdf from "@/components/Pdf";
import Player from "@/components/Player.vue";
export default {
  name: "About",
  components: {
    Header,
    Pdf,
    Player,
    Bottom
  },
  computed: {
    lists: function () {
      var array = {};
      this.list.map((item, index) => {
        if (item.id == this.queryid.id) {
          array = item;
        }
      });
      return JSON.parse(JSON.stringify(array));
    },
  },
  data() {
    return {
      queryid: {},
      showpdfs:false,
      list: [
         { id: "1",
          title: "职场达人",
          image: require("../assets/zhi.png") ,
         // player:'https://obs.youmengv.com/web/doc/zhichang.mp4',
          pdfurl:'https://obs.youmengv.com/web/doc/zhichang.pdf',
          case:[require('../assets/zhiplay1.png'),require('../assets/zhiplay2.png'),require('../assets/zhiplay3.png')],
          content:`<span style="color:black;font-weight:bolder">基本玩法：</span>选手将被分为不同的队伍进行PK，总计12题。每次PK将在“指定题库”中随机出现题目，双方玩家对题目答案进行A\\B\\C\\D的选择。选择正确则得分，选择错误则不得分。值得注意的是，“越快选择正确答案的玩家会得到越高的分数”，所以“又快又准”是制胜的诀窍。但也别心急到点错哟~~。</br><span style="color:black;font-weight:bolder">个人排位赛：</span>没有对手，虽然可以无限循环挑战，但是每次挑战，只要“答错一次就Over”。系统将记录你本次的最高挑战成功题目数作为本次挑战的成绩。个人排位赛的排名在主页就可以看到哟，试着一次答对100题，成为全公司的“最强大脑”吧…。</br><span style="color:black;font-weight:bolder">团队晋级赛：</span>特定时间进行的比赛。选手将通过“邀请队友”的形式形成“固定队伍”与其他的战队进行PK。PK模式与LOL模式非常相像。“随机匹配对方队伍”，按照“3V3”的形式进行PK，总计12题，获胜的小组晋级，落败的小组降级。在限定时间内，段位最高的战队，便是“最强战队”！`,
          },
        
        {
          id: "2",
          title: "团建武侠传",
          image: require("../assets/wu.png"),
          //player:'https://obs.youmengv.com/web/doc/tuan.mp4',
          pdfurl:'https://obs.youmengv.com/web/doc/wuxia.pdf',
          case:[require('../assets/wu1.png'),require('../assets/wu2.png')],
          content: "飞雪连天射白鹿,笑书神侠倚碧鸳。金庸先生创造了一个荡气回肠的快意江湖。张无忌赵敏周芷若，杨过小龙女，郭靖黄蓉，这些名字都深深的印在了我们的脑海中。一说起他们，都会勾起我们心中的武侠梦。今天就让我们重回热血江湖，致敬金庸经典。我们在20万平方的土地上，聚集了上百位武侠，并设立了“武林擂台” ，玩家将被分为不同的团队，在这片浩瀚的土地上，去寻找在野武侠，组合出团队最强的武林组合，并与其它的玩家进行对战及交易。目的则是为了赢得最终“武林盟主”荣誉。",
        },
       {
          id: "3",
          title: "AR 跳一跳",
          image: require("../assets/tiao.png"),
          player:'https://obs.youmengv.com/web/doc/tiao.mp4',
          pdfurl:'https://obs.youmengv.com/web/doc/tiao.pdf',
          content:
            "挑战者将分为5人一组的小组进行挑战。项目玩法非常简单，在现实世界中将出现若干的虚拟跳台，每个小组的目标是使用手机操控代表自己的棋子，进行跳跃，让小组所有成员跳到更远的跳台上，只有所有成员都成功跳到下一个跳台时，才会开启之后的跳台。当有团队成员跳跃失败的时候，会在3秒时间后复活，然后重新挑战。项目共分为 “练习阶段”、“任务阶段”、“竞技阶段”、“世界排名挑战赛”四个阶段。",
        },
        {
          id: "4",
          title: "AR 团队飞碟多向赛",
          image: require("../assets/tuan.png"),
         // player:'https://obs.youmengv.com/web/doc/artuan.mp4',
          pdfurl:'https://obs.youmengv.com/web/doc/artuan.pdf',
          content:'挑战者分为1-5人/组进行挑战。项目还原真实奥运会飞碟射击项目，创建了虚拟的射击场景。每个小组的目标是使用手机\AR枪\AR头盔手柄等形式，进入虚拟世界对各个方向射出的飞碟进行射击，并夺得团队射击排行。与个人飞碟射击赛不同的是，团队射击除了考验个人能力的成长之外，更考验团队的提前分工和安排，只有更合理的配合和提前的战术拟定才能真正夺得高分。项目共分为8个挑战关卡，每个关卡难度将逐步上升，每个关卡都将记录所有小组的排名进行奖惩。所有关卡挑战结束之后，将选出公司最强战队，进行世界排名挑战。'
        },
        { id: "5", 
          title: "掘金骑士团", 
          image: require("../assets/jue.jpg") ,
         // player:'https://obs.youmengv.com/web/doc/juejin.mp4',
          pdfurl:'https://obs.youmengv.com/web/doc/juejin.pdf',
          content:`《掘金骑士》我们通过玩游戏即可获得想要学习知识无论是企业文化知识还是应知应会
还是产品，制度，政策……
都可以在游戏中以不一样的篇章来挑战
挖掘知识的宝箱
解锁一道道知识题库
在规定时间完成足够积分才可以闯关
骑士探索自主研发的学习型游戏
借助微信小程序进行体验
个人闯关，团队打榜……
题库房间定制，知识宝箱定制
文字，视频，图片，声音均可作为宝箱任务
让枯燥的知识学习更像玩游戏。

特别适合年轻一代新员工报到前的预热游戏，更自主，不随时间，地点，疫情的影响。让玩游戏成为一种学习方式。`},
 {
          id: "6",
          title: "和未来打招呼",
          image: require("../assets/wei.png"),
         // player:'https://obs.youmengv.com/web/doc/weilai.mp4',
          pdfurl:'https://obs.youmengv.com/web/doc/weilai.pdf',
          content:'一款为公司新员工培训定制的小程序，签到打卡，每日考核答题，互动活动，投票等。'
        },
      ],
      videolpayerdialog: false,
      playerurl: "",
      showin:false
    };
  },
  methods: {
    playvideoPlayer() {
      this.playerurl = this.list[this.queryid.id-1].player;
      this.videolpayerdialog = true;
      console.log(this.videolpayerdialog);
    },
    playerClick() {
      this.videolpayerdialog = false;
    },
    screewidthchange(e) {
      
      if (e < 768) {
        this.showin = true
      } else {
       this.showin = false
      }
    },
  },
  mounted: function () {
    this.queryid = this.$route.query;
  },
};
</script>
<style scoped>
.about {
  width: 100%;
  height: 100%;
}
.header {
  width: 100%;
  height: 80px;
}
.mycont {
  width: 100%;
  padding: 100px 0;
}
.showlist {
  width: 100%;
  max-height: auto;
  padding: 100px 0;
  background-color: #f6f7ff;
}

.showlist_in {
  width: 70%;
   max-height: auto;
  margin: 0 auto;
  position: relative;
  padding: 0 40px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.showlist_in .in_left {
  max-width: 50%;
  text-align: left;
  color: #6a8695;
}
.showlist_in .in_left h2{
  margin-top: 100px;
}
.showlist_in .in_left p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 30px;
}
.showlist_in .in_left ul {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
.showlist_in .in_left ul li {
  width: 50%;
  float: left;
  padding-right: 10px;
}

.showlist_in .in_right {
  max-width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.showlist_in .in_lefts{
   max-width: 85%;
}
.showlist_in .in_rights{
   max-width: 100%;
}
.showlist .showlist_in .in_left i {
  font-size: 50px;
  margin-bottom: 20px;
  display: block;
}
.showlist .showlist_in .in_left ul li span {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 26px;
}
.in_right img {
  max-width: 65%;
}
.in_rights img {
  max-width: 100%;
}
.cont_l_bot {
  position: absolute;
  top: 10px;
  width: 100px;
  display: flex;
  vertical-align: middle;
  justify-content: flex-start;
}
.botl_sub {
  background-color: #00c99c;
  text-transform: uppercase;
  font-family: "Work Sans", sans-serif;
  color: #fff;
  font-size: 12px;
  border-radius: 2px;
  line-height: 50px;
  font-weight: 600;
  display: inline-block;
  height: 50px;
  letter-spacing: 0.5px;
  padding: 0 40px;
  text-decoration: none;
  box-shadow: 0 3px 10px rgb(0 201 156 / 50%);
}
.botl_sub:hover {
  transition: all 0.5s;
  box-shadow: 0 0 0 0 !important;
}
.botr_sub {
  margin-left: 20px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Work Sans", sans-serif;
  text-transform: uppercase;
  color: #263b5e;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.botr_sub i {
  background-color: #f42267;
  box-shadow: 0px 15px 25px 0px rgb(244 34 103 / 50%);
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 15px;
  margin-right: 10px;
}
.botr_sub:hover {
  color: #007bff;
}
.botr_sub:hover i {
  box-shadow: 0 0 0 0 !important;
}
.videoplayer {
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  z-index: 99;
}
.player {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 50%;
  height: 36%;
  animation: big 1s;
}
@keyframes big {
  0% {
    opacity: 0;
    transform: scale(0.96, 0.96);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
.playerbut {
  z-index: 9994;
  width: 35px;
  height: 35px;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-appearance: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding: 0;
  color: #fff;
  font-style: normal;
  font-size: 35px;
  font-family: Arial, Baskerville, monospace;
  line-height: 35px;
  text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  border: 0;
  background: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.apdfdiv{
    width: 100%;
    padding: 100px 0;
}
.div_top{
  margin-bottom: 50px;
  font-size: 1.4rem;
}
.case{
  max-width: 100%;
  padding: 100px 0;
  font-size: 1.4rem;
  background-color: #f6f7ff;

}
.case .case_img{
  margin: 0 auto;
  max-width: 80%;
  display: flex;
}
.case .case_img .viewer{
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.case .case_img img{
  margin-left: 40px;
  margin-top: 20px;
  max-width: 35%;
  max-height: 400px;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 80%);
}
</style>