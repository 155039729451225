<template>
     <div class="search bottom">
      <div class="s_bgl"></div>
      <div class="s_bgr"></div>
      <div class="search_cont">
        <div class="bottom_in">
          <div class="clo1">
            <a href="">
              <img src="../assets/logo-dark.png" alt="" />
            </a>
            <p>
              上海界变网络科技有限责任公司
            </p>
          </div>
          
          <div class="clo3">
            <h4>公司地址</h4>
            <span>上海市浦东新区张江高科技园区</span>
            <span>纳贤路800号科海大楼2层216室</span>
            <!-- <span>(+123) 456 789 101</span> -->
          </div>
          <div class="clo2">
            <h4>联系方式</h4>
            <ul>
              <li>电话：13817632980</li>
              <li>微信：sim_xuansx</li>
              <li>邮箱：xuansx@qq.com</li>
              <!-- <li>Our Services</li>
              <li>Clients Reviews</li>
              <li>Contact Us</li> -->
            </ul>
          </div>
          <!-- <div class="clo4">
            <h4>Newslatter Subscription</h4>
            <p>
              Subscribe and get 10% off from our
              <br />
              architecture company.
            </p>
            <div></div>
          </div> -->
        </div>
      <div class="bot_bot">
          <div>上海界变网络科技有限公司 ©版权所有</div> <div><a href="https://beian.miit.gov.cn/#/Integrated/index"> 沪ICP备2021001935号-1</a></div>
      </div>
      </div>
    </div>
</template>
<script>
export default {
    name:'Bottom',
    
}
</script>
<style scoped>
.search {
  position: relative;
  width: 100%;
  padding: 100px 0 100px;
  background-color: #151948 !important;
}
.s_bgl {
  background-image: url(../assets/left-design-white.png);
  background-repeat: no-repeat;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 0;
}
.s_bgr {
  background-image: url(../assets/right-design-white.png);
  background-repeat: no-repeat;
  background-position: top right;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  z-index: 0;
  display: flex;
  justify-content: center;
}
.search_cont {
  color: white;
  margin: 0 auto;
  width: 70%;
  
}
.cont_in {
  width: 730px;
}
.cont_in p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 15px;
  color: #ddd !important;
}
.search_cont h2 {
  color: white !important;
}
.search_cont .input {
  width: 730px;
  height: 60px;
}
.bottom_in {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.bottom_in div {
  padding-right: 15px;
  padding-left: 15px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  color: #ddd !important;
}
.bottom_in .clo1 {
  width: 225px;
}
.bottom_in .clo1 img{
  width:145px;
  height: 45px;
}
.bottom_in .clo1 p {
  text-align: left;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 15px;
}
.bottom_in .clo2 {
 width: 225px;
  text-align: left;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 15px;
}
.bottom_in h4 {
  color: white;
  display: block;
  width: 100%;
}
.bottom_in .clo2 ul {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}
.bottom_in .clo3 {
  width: 225px;
}
.bottom_in .clo3 h4{
 display: block;
 width: 100%;
 text-align: left;
}
.bottom_in .clo3 p {
  text-align: left;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 15px;
}

.bottom_in .clo3 span {
  text-align: left;
  display: block;
  
}
.bottom_in .clo4 {
  text-align: left;
  width: 255px;
}
.bot_bot{
  position: relative;
  z-index: 1;
  margin-top: 30px;
  width: 100%;
  height: 50px;
  display: flex;
 justify-content: center;
 flex-wrap: wrap;
 align-items: center;
 border-top: 1px solid hsla(0,0%,100%,.3);

}
.bot_bot div{
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
} 
.bot_bot a{
   color: white !important;
   z-index: 1;
   margin-left: 5px;
}
.bot_bot a:hover{
 text-decoration: underline solid white;
}
</style>