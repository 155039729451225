<template>
  <div class="home">
    <Header @scroll="scrollchange" @screewidth="screewidthchange"></Header>
    <div ref="header" class="header_height"></div>
    <div class="banners">
      <div class="banner" ref="banner">
        <div class="banner_bg"></div>
        <div class="anim-elements">
          <div class="anim-element"></div>
          <div class="anim-element"></div>
          <div class="anim-element"></div>
          <div class="anim-element"></div>
          <div class="anim-element"></div>
        </div>
        <div class="nav">
          <div class="nav_row" :class="shownavrows ? 'nav_rows' : ''">
            <div class="con_left">
              <h1>
                {{ Company.profile }}
              </h1>
              <p>
                {{ Company.content }}
              </p>
              <div class="cont_l_bot">
                <a
                  class="botl_sub"
                  href="javascript:void(0)"
                  @click="Bottomclick"
                  >联系我们</a
                >
                <!-- <a class="botr_sub" href="javascript:void(0)" @click="playvideoPlayer">
                <i class="fas fa-play"></i>
                How It Works
              </a> -->
              </div>
            </div>
            <div class="con_right">
              <img src="../assets/hero-1.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="project">
      <div class="project_in">
        <div class="top">
          <!-- <span>Projects</span> -->
          <h1>产品目录</h1>
        </div>
        <div class="row" :class="rows ? 'rows' : ''">
          <div class="row_in" :key="keys">
            <swiper
              class="swiper"
              :options="swiperOption"
              @change="changeSwiper"
              ref="mySwiper"
            >
              <swiper-slide v-for="(item, index) in swipers" :key="index">
                <div class="slide_top">
                  <img class="img" :src="item.image" alt="" />
                   <div class="understand" @click="Understand(item.id)">
                  了解更多
                </div>
                </div>
               
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination"  slot="pagination" ref="swiper"></div>
          </div>
        </div>
      </div>
    </div>
   <div class="project projects">
    <div class="project_in">
      <div class="top">
        <!-- <span>Projects</span> -->
        <h1>{{ cooperation.title }}</h1>
      </div>
      <div class="row " :class="[rows ? 'hrow' : 'hrows',rows ? 'rows' : '']">
        <div class="row_in" :key="keys">
          <swiper
            class="swiper"
            :options="swiperOptions"
            @change="changeSwiper"
            ref="mySwiper"
            
          >
            <swiper-slide
              v-for="(item, index) in cooperation.list"
              :key="index"
            >
              <div class="slide_top">
                <h3>{{ item.name }}</h3>
                <img class="imgs" :src="item.img" alt="" />
                <span>{{ item.content }}</span>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-paginations" slot="pagination" ref="swiper"></div>
        </div>
      </div>
    </div>
  </div>
   <!-- <Swiper></Swiper> -->
    <div class="showlists" ref="showlist">
      <div class="showlist_in">
        <div class="in_left">
          <!-- <span>AR</span> -->
          <h1>合作伙伴</h1>

         <div class="hpower">
           <img v-for="(i,index) in hpower" :key="index" :src="i" alt="">
         </div>
        </div>
      </div>
    </div>
   <Bottom></Bottom>
    <div class="videoplayer" v-if="videolpayerdialog">
      <Player class="player" :player-url="playerurl"></Player>
      <button class="playerbut" @click="playerClick">×</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import Player from "@/components/Player.vue";
import Bottom from "@/components/Bottom.vue";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "Home",
  components: {
    Header,
    swiper,
    swiperSlide,
    Player,
    Bottom
  },
  
  computed:{
    swiper() {
    return this.$refs.mySwiper.swiper;
  },

  },
  data() {
    return {
      hpower:[],
      keys:0,
      Company: {
        profile: "公司简介",
        content:
          "团建&企业培训专业服务供应商，年服务近千国内外企事业单位，提供，线上线下多种团建产品，并未VIP客户提供相关定制开发。产品技术覆盖小程序，小游戏，H5，VR/AR，高精度室内定位互动等。",
      },
      swipers: [
        { id: "1", title: "职场达人", image: require("../assets/zhi.png") },
        { id: "2", title: "团建武侠传", image: require("../assets/wu.png") },
         { id: "3", title: "AR 跳一跳", image: require("../assets/tiao.png") },
        {
          id: "4",
          title: "AR 团队飞碟多向赛",
          image: require("../assets/tuan.png"),
        },
        { id: "5", title: "掘金骑士团", image: require("../assets/jue.jpg") },
        { id: "6", title: "和未来打招呼", image: require("../assets/wei.png") },
      ],
      cooperation: {
        title: "合作开发",
        list: [
          {
            name: "Happy Show",
            img: require("../assets/happy.png"),
            content: "为团建公司定制合作开发的小程序",
          },
          {
            name: "仿旋转大师",
            img: require("../assets/xuan.png"),
            content: "为客户的定制的小游戏",
          },
          {
            name: "解救美少女",
            img: require("../assets/da.png"),
            content: "为客户的定制的小游戏",
          },
          {
            name: "策略运营小游戏",
            img: require("../assets/chan.png"),
            content: "为客户的定制的小游戏",
          },
        ],
      },
      swiperOption: {
        autoplay: false, // 自动轮播
        slidesPerView: 3,
        speed: 50, // 轮播速度
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        on: {
          slideChangeTransitionEnd: function () {
            // ...
          },
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: false, //修改swiper的父元素时，自动初始化swiper
        // onSlideChangeEnd: function (swiper) {
        //   swiper.update();
        //   mySwiper.startAutoplay();
        //   mySwiper.reLoop();
        // },
      },
      swiperOptions: {
        autoplay: true, // 自动轮播
        slidesPerView: 3,
        speed: 50, // 轮播速度
        pagination: {
          el: ".swiper-paginations",
          clickable: true,
        },
        on: {
          slideChangeTransitionEnd: function () {
            // ...
          },
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: false, //修改swiper的父元素时，自动初始化swiper
        onSlideChangeEnd: function (swiper) {
          swiper.update();
          mySwiper.startAutoplay();
          mySwiper.reLoop();
        },
      },
      divHeight: [],
      rows: false,
      shownavrows: false,
      videolpayerdialog: false,
      classul: false,
      playerurl: "",
    };
  },
  watch:{
    swiperOption(nes){
     console.log(nes,'5');
    }
  },
  methods: {
    Understand(e) {
      this.$router.push({ path: "/About", query: { id: e } });
    },
    screewidthchange(e) {
      if (e < 768) {
        this.shownavrows = true;
        this.rows = true;
        this.swiperOption.slidesPerView = 1;
        this.swiperOptions.slidesPerView = 1;
        this.keys+=1
        this.classul = true;
      } else {
        this.shownavrows = false;
        this.rows = false;
        this.swiperOption.slidesPerView = 3;
        this.swiperOptions.slidesPerView = 3;
        this.keys+=1
        this.classul = false;
      }
    },

    Bottomclick() {
      console.log(99);
      window.scrollTo(0, 50000);
    },
    // playvideoPlayer(){
    //   this.playerurl = require('../assets/shipin.mp4')
    //   this.videolpayerdialog = true
    //   console.log(this.videolpayerdialog);
    // },
    playerClick() {
      this.videolpayerdialog = false;
    },
    getdivheigth() {
      //   let headerH = this.$refs.header.offsetHeight
      //   let contentlistH = this.$refs.contentlist.offsetHeight
      //   let showlistH = this.$refs.showlist.offsetHeight
      //   let showlistsh = this.$refs.showlists.offsetHeight
      //   let searchH = this.$refs.search.offsetHeight
      //   this.divHeight=[headerH,contentlistH,showlistH,showlistsh,searchH]
      //  console.log();
    },
    scrollchange(e) {
      let divHeight = this.divHeight;
      //console.log('ok',document.documentElement.clientHeight);
      if (e > divHeight[0] + divHeight[1] - 100) {
      }
    },
    changeSwiper(e) {},
    showCooperation(){
      let array = [];
      for (let index = 0; index < 19; index++) {
        array[index] = require(`../assets/${index}.png`)
      }
      this.hpower = array
    }
  },
  mounted: function () {
    this.getdivheigth();
    this.showCooperation()
  },
};
</script>
<style scoped>
.home {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.anim-elements {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
.anim-elements .anim-element:nth-child(1) {
  background: rgba(0, 201, 157, 0.6);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 12%;
  left: 15%;
  animation: animTwo 13s infinite linear;
  z-index: 1;
}
.anim-elements .anim-element:nth-child(2) {
  border: 5px solid rgba(0, 153, 229, 0.3);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  top: 15%;
  left: 45%;
  -webkit-animation: animOne 15s infinite linear;
  animation: animOne 15s infinite linear;
}
.anim-elements .anim-element:nth-child(3) {
  border: 5px solid rgba(244, 34, 104, 0.3);
  width: 25px;
  height: 25px;
  bottom: 20%;
  left: 30%;
  -webkit-animation: animFour 15s infinite linear alternate;
  animation: animFour 15s infinite linear alternate;
}
.anim-elements .anim-element:nth-child(4) {
  background: rgba(252, 162, 73, 0.8);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  bottom: 15%;
  right: 25%;
  -webkit-animation: animFive 15s infinite linear alternate;
  animation: animFive 15s infinite linear alternate;
}
.anim-elements .anim-element:nth-child(5) {
  background: rgba(100, 45, 250, 0.4);
  width: 3px;
  height: 20px;
  top: 18%;
  right: 25%;
  -webkit-animation: animFour 15s infinite linear alternate;
  animation: animFour 15s infinite linear alternate;
}
.anim-elements .anim-element:nth-child(5):after {
  left: -6px;
}

.anim-elements .anim-element:nth-child(5):after,
.anim-elements .anim-element:nth-child(5):before {
  /* content: ""; */
  display: block;
  width: 100%;
  height: calc(50% - 2px);
  top: 6px;
  background: inherit;
  position: absolute;
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
@keyframes animFive {
  0% {
    transform: translate(61px, -99px) rotate(0deg);
  }

  21% {
    transform: translate(4px, -190px) rotate(38deg);
  }
  41% {
    transform: translate(-139px, -200px) rotate(74deg);
  }
  60% {
    transform: translate(-263px, -164px) rotate(108deg);
  }
  80% {
    transform: translate(-195px, -49px) rotate(144deg);
  }
  100% {
    transform: translate(-1px, 0px) rotate(180deg);
  }
}
@keyframes animFour {
  0% {
    transform: translate(-300px, 151px) rotate(0deg);
  }

  100% {
    transform: translate(251px, -200px) rotate(180deg);
  }
}
@keyframes animOne {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }

  20% {
    transform: translate(73px, -1px) rotate(35deg);
  }
  40% {
    transform: translate(141px, 72px) rotate(75deg);
  }
  60% {
    transform: translate(83px, 122px) rotate(110deg);
  }
  80% {
    transform: translate(-40px, 72px) rotate(145deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}
@keyframes animTwo {
  0% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
  20% {
    transform: translate(73px, -1px) rotate(36deg) scale(0.9);
  }
  40% {
    transform: translate(141px, 72px) rotate(72deg) scale(1);
  }
  60% {
    transform: translate(83px, 122px) rotate(108deg) scale(1.2);
  }
  80% {
    transform: translate(-40px, 72px) rotate(144deg) scale(1.1);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg) scale(1);
  }
}
.anim-elements .anim-element {
  position: absolute;
}
.banners {
  width: 100%;
  max-height: 900px;
}
.banner {
  top: 0;
  position: relative;
  height: 100%;
  width: 100%;
  border-top: 1px solid #eaeaea;
  background-color: #f6f7ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #eee;
}
.header_height {
  width: 100%;
  height: 80px;
}
.banner_bg {
  background-image: url(../assets/hero-bg.png);
  background-repeat: no-repeat;
  background-position: right top;
  background-size: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
}

.nav {
  padding: 100px 0;
  height: 100%;
  width: 100%;
}
.nav_row {
  display: flex;
  justify-content: center;
}
.nav_rows {
  flex-wrap: wrap;
}
.con_left {
  padding: 3% 0;
  flex: 0 0 50%;
  max-width: 50%;
}
.con_right {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 3%;
}
.con_right img {
  max-width: 100%;
}
.con_left h1 {
  font-size: 3em;
  font-weight: 600;
  line-height: 56px;
  margin: 0 0 50px;
  text-align: left;
  color: #263b5e;
  letter-spacing: -2px;
  font-family: "Poppins", sans-serif;
}
.con_left p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 50px;
  color: #6a8695;
  text-align: left;
}
.cont_l_bot {
  width: 100%;
  height: 50px;
  display: flex;
  padding-bottom: 50px;
  vertical-align: middle;
  justify-content: flex-start;
}
.botl_sub {
  background-color: #00c99c;
  text-transform: uppercase;
  font-family: "Work Sans", sans-serif;
  color: #fff;
  font-size: 12px;
  border-radius: 2px;
  line-height: 50px;
  font-weight: 600;
  display: inline-block;
  height: 50px;
  letter-spacing: 0.5px;
  padding: 0 40px;
  text-decoration: none;
  box-shadow: 0 3px 10px rgb(0 201 156 / 50%);
}
.botl_sub:hover {
  transition: all 0.5s;
  box-shadow: 0 0 0 0 !important;
}
.botr_sub {
  margin-left: 20px;
  font-size: 12px;
  font-weight: 600;
  font-family: "Work Sans", sans-serif;
  text-transform: uppercase;
  color: #263b5e;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  text-decoration: none;
  height: 50px !important;
}
.botr_sub i {
  background-color: #f42267;
  box-shadow: 0px 15px 25px 0px rgb(244 34 103 / 50%);
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-size: 15px;
  margin-right: 10px;
}
.botr_sub:hover {
  color: #007bff;
}
.botr_sub:hover i {
  box-shadow: 0 0 0 0 !important;
}

.content_list {
  width: 100%;
  height: 470px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #eee;
  background: #f8f6f6;
}
.contents {
  width: 70%;
  height: 267px;
  display: flex;
  justify-content: center;
}
.contents .list {
  box-shadow: 0px 50px 100px 0px rgb(64 1 4 / 10%),
    0px -6px 0px 0px rgb(248 99 107 / 0%);
  width: 23%;
  height: 276px;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  top: 0;
  transition: top 0.1s linear;
}
.contents .list:hover {
  top: -10px;
}

.contents .list .list_in {
  padding: 30px 15px;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.contents .in1 {
  animation: fadeaum 0.4s 1;
}
.contents .in2 {
  animation: fadeaum 0.8s 1;
}

.contents .in3 {
  animation: fadeaum 1.2s 1;
}

.contents .in4 {
  animation: fadeaum 1.6s 1;
}

@keyframes fadeaum {
  /*设置内容由显示变为隐藏*/
  0% {
    visibility: hidden;
    opacity: 0;
    top: 20px;
  }
  100% {
    visibility: visible;
    opacity: 1;
    top: 0px;
  }
}
.contents .list .list_in i {
  font-size: 50px;
  margin-bottom: 20px;
  display: block;
}
/* .ai {
  font-family: "saasbiz" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.ai:before {
  content: "\e903";
} */
.ai img {
  width: 80px;
  height: 80px;
}
.contents .list .list_in p {
  font-size: 0.8rem;
  line-height: 26px;
  margin-bottom: 15px;
  color: #2c3e50;
}
.showlist {
  width: 100%;
  padding: 130px 0 80px;
  background-color: #f6f7ff;
}
.showlists {
  width: 100%;
  max-height: 1800px;
  padding: 130px 0 80px;
  background-color: #ffffff !important;
}
.showlist_in {
  max-width: 80%;
  margin: 0 auto;
  position: relative;
  padding: 0 15px;
  display: flex;
  justify-content: center;
}
.showlist_in .in_left {
  width: 100%;
  text-align: left;
  color: #6a8695;
  display: flex;
  justify-content: center;
}
.showlist_in .in_left h1 {
  position: absolute;
  top: -120px;
  font-size: 3em;
}
.showlist_in .in_left .ul {
  display: flex;
  flex-wrap: wrap;
}
.showlist_in .in_left .ul li {
  max-width:45%;
}
.showlist_in .in_left .ul li div {
  width: 100%;
}
.showlist_in .in_left ul li h3 {
  font-size: 1rem;
  display: block;
  width: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
  height: 30px;
}
.showlist_in .in_left ul li div {
  width: 70%;
  height: 70%;
}
.showlist_in .in_left ul li img {
  width: 100%;
  height: 100%;
}
.showlist_in .in_left ul {
  width: 100%;
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
.showlist_in .in_left ul li {
  max-width: 38%;
  float: left;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-right: 10px;
}
.showlist_in .in_left ul li span {
  display: block;
  width: 100%;
  text-align: center;
}
.showlist_in .in_right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.showlist .showlist_in .in_left i {
  font-size: 50px;
  margin-bottom: 20px;
  display: block;
}
.showlist .showlist_in .in_left ul li span {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 26px;
}
.in_right img {
  width: 95%;
}

.cloud {
  background-image: url(../assets/wave.png);
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  bottom: -350px;
  z-index: 0;
}

.project {
  width: 100%;
  padding-bottom: 150px;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white ;
}
.projects {
  background:#f6f7ff !important;
}
.project .project_in {
  width: 85%;
}
.project .project_in .top {
  margin-bottom: 40px !important;
  text-align: center !important;
}
.project .project_in .top span {
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  color: #263b5e;
  font-weight: 600;
  letter-spacing: 0;
  font-family: "Work Sans", sans-serif;
  position: relative;
  margin-bottom: 10px;
  z-index: 1;
}
.project .project_in .top h1 {
  font-size: 3em;
}
.project .project_in .row {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  position: relative;
}
.project .project_in .row .row_in {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 81%;
}
.row .slide_top:hover .understand {
  visibility: visible;
}
@keyframes under {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
.understand {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(to right, rgb(255, 255, 255,0.6) , rgb(0, 0, 0,0.6),rgb(255, 255, 255,0.6));
 
  display: flex;
  justify-content: center;
  align-items: center;
  color: #d8d8d8;
  font-weight: bold;
  z-index: 1;
  visibility: hidden;
  transition: under 1s;
}
.row .swiper-slide {
  position: relative;
  width: 32% !important;
  height: 495px;
  margin-right: 1.5%;
}
.hrows .swiper-slide {
  height: 720px !important;
}
.hrow .swiper-slide {
  height: 460px !important;
}
.rows .swiper-slide {

  width: 97% !important;
  height: 395px;
  margin-right: 3%;
}
.slide_top {
  position: relative;
  width: 100%;
  height: 100%;
}
.imgs{
  margin-bottom: 20px;
  width: 100%;
  height: 80%;
}
.img {
  max-width: 100% !important;
  max-height: 100% !important;
}
.slide_bot {
  width: 100%;
  height: 154px;
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);
}
.swiper-pagination {
  position: absolute;
  bottom: -80px;
}
.swiper-pagination >>> span {
  margin-left: 15px !important;
  background: #622dfa;
}
.swiper-pagination >>> .swiper-pagination-bullet-active::before {
  display: block;
  border: 2px solid #622dfa;
  position: relative;
  content: "";
  top: 4px !important;
  left: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s linear;
}
.swiper-paginations {
  position: absolute;
  bottom: -80px;
}
.swiper-paginations >>> span {
  margin-left: 15px !important;
  background: #622dfa;
}
.swiper-paginations >>> .swiper-pagination-bullet-active::before {
  display: block;
  border: 2px solid #622dfa;
  position: relative;
  content: "";
  top: 4px !important;
  left: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s linear;
}

.videoplayer {
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  z-index: 99;
}
.player {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 50%;
  height: 36%;
  animation: big 1s;
}
@keyframes big {
  0% {
    opacity: 0;
    transform: scale(0.96, 0.96);
  }
  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}
.playerbut {
  z-index: 9994;
  width: 35px;
  height: 35px;
  position: fixed;
  right: 0;
  top: 0;
  -webkit-appearance: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  padding: 0;
  color: #fff;
  font-style: normal;
  font-size: 35px;
  font-family: Arial, Baskerville, monospace;
  line-height: 35px;
  text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
  border: 0;
  background: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.hpower{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  
}
.hpower img{
  max-width: 300px;
  height: 50px;
  margin-top: 10px;
  margin-left: 15px;
}
</style>