<template>
  <div ref="detail">
    <div :class="loading ? 'hide' : ''">
      <div ref="pdf" class="pdfd">
        <div
          class="swiper-button-prev"
          slot="button-prev"
          @click="direction(1)"
        ></div>
        <div
          class="swiper-button-next"
          slot="button-next"
          @click="direction(2)"
        ></div>
        <swiper
          class="swiper"
          :options="swiperOption"
          @change="changeSwiper"
          ref="mySwiper"
        >
          <swiper-slide class="pdfdiv" v-for="i in numPages" :key="i">
            <pdf
              class="showpdf"
              :src="src"
              :page="i"
              @page-loaded="pageLoaded($event)"
            ></pdf
          ></swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>
<script>
import pdf from "vue-pdf";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  name: "Pdf",
  components: {
    pdf,
    swiper,
    swiperSlide,
  },
  props: ["pdfUrl"],
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  data() {
    return {
      swiperOption: {
        autoplay: true, // 自动轮播
        slidesPerView: 1,
        speed: 1000, // 轮播速度
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        loop: true,
        on: {
          slideChangeTransitionEnd: function (e) {},
        },
      },
      loading: true, // 显示加载效果
      src: "",
      numPages: undefined, // 总页数
      curPageNum: 0, //当前页
    };
  },
  methods: {
    direction(e) {
      //this.swiper.slidePrev();
      console.log(e, this.swiperOption.slidesPerView);
    },
    pageLoaded(e) {
      this.curPageNum = e;
      if (this.curPageNum == this.numPages) {
        //加载完最后一页
        this.loading = false;
      }
    },
    changeSwiper(e) {},
    picture() {
      console.log(55);
    },
  },
  mounted() {
    let that = this;
    setTimeout(() => {
      if (this.pdfUrl != "" &&this.pdfUrl != undefined) {
        that.$refs.detail.scrollIntoView(true);
        that.src = pdf.createLoadingTask(this.pdfUrl); // url是pdf文件的全路径
        that.src.promise.then((pdf) => {
          that.numPages = pdf.numPages;
        });
      }
    }, 1);
  },
};
</script>


<style scoped>
.hide {
  visibility: hidden;
}
.pdfdiv {
  display: flex;
  justify-content: center;
}
.pdfdiv span {
  width: 100%;
  height: 100%;
  margin-left: 8px;
}
.pdfd {
  position: relative;
  display: flex;

  align-items: center;
}
.swiper-button-prev {
  position: absolute;
  z-index: 99;
  width: 34px;
  height: 63px;
  border: none;
  left: 18%;
  color: rgba(255, 255, 255, 0.5);
}
.swiper-button-next {
  position: absolute;
  z-index: 99;
  width: 34px;
  height: 63px;
  border: none;
  right: 18%;
  color: rgba(255, 255, 255, 0.5);
}
.swiper {
  width: 70%;
}
.swiper-slide {
  width: 100% !important;
}
.pdfdiv span canvas {
  box-shadow: 0 4px 20px 0 rgb(0 0 0 / 10%);
}
</style>