<template>
  <div class="header" ref="header" :class="scrolls==true?'':(scroll>0?'scroll':'')">
       <div class="nav" ref="nav">
         <div class="logo"><a href=""><img src="../assets/logo-dark.png" alt=""></a></div>
         <div class="slickmenu" v-if="showsLickLists">
            <ul class="slicknav_nav">
              <li  v-for="(item,index) in nav" :key="index">
                <a class="a_out" href="javascript:void(0)" @click="showsLicksSmallList(index)">
                  <a class="a_in" href="javascript:void(0)">{{item.title}}</a>
                  <span class="style">{{index==ids&&item.show==true?'▼':'►'}}</span>
                </a>
                <ul v-if="item.show">
                    <li v-for="(items,indexs) in item.list" :key="indexs"><a href="">{{items.titles}}</a> </li>
                  </ul>
                </li>
            </ul>
         </div>
         <div class="menu" >
           <div class="menu_l" v-if="!header">
            <ul>
             <li v-for="(item,index) in nav" :key="index">
               <a href="javascript:void(0)" @click="jumpWeb(index)">{{item.title}}</a>
                <ul>
                  <li v-for="(items,indexs) in item.list" :key="indexs"><a href="">{{items.titles}}</a> </li>
                </ul>
             </li>
           </ul>
           </div>
           <div class="menu_r">
             <div class="icon">
               <!-- <i class="fa fa-shopping-basket"></i>
               <span class="counts">0</span> -->
             </div>
             <div class="but" v-if="header">
               <a href="javascript:void(0)" @click="showsLickList">
                <span></span>
                <span></span>
                <span></span>
               </a>
             </div>
           </div>
         </div>
       </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data(){
   return{
     screenWidth: document.body.clientWidth,
     scroll:0,
     scrolls:true,
     header:false,
     width:null,
     showsLickLists:false,
     ids:-1,
     nav:[
       {title:'首页',show:false,list:[]},
       {title:'产品目录',show:false,list:[]},
       {title:'合作开发',show:false,list:[]},
       {title:'合作伙伴',show:false,list:[]},
       {title:'联系我们',show:false,list:[]},
      //  {title:'PAGES',list:[{titles:'Home Design Studio'},{titles:'Home Design Studio'},{titles:'Home Design Studio'}]},
      //  {title:'LOGO',list:[{titles:'Home Design Studio'},{titles:'Home Design Studio'},{titles:'Home Design Studio'}]},
      //  {title:'CONTACT',list:[{titles:'Home Design Studio'},{titles:'Home Design Studio'},{titles:'Home Design Studio'}]}
     ],
     
   }
  },
  watch: {
    screenWidth(news){
       this.screewidth(news)
      //console.log(news,'11',);
    }
  },
  methods:{
    screewidth(news){
    this.$emit('screewidth',news)
    if(news<=768){
        this.header = true
        this.$refs.nav.style.width = '540px'
        }else if(news<=992&&news>=768){
         this.$refs.nav.style.width = '960px'
          this.showsLickLists=false
          this.header=false
        }else if(news>1200){
          this.$refs.nav.style.width = '1140px'
          this.showsLickLists=false
          this.header=false
        }
    },
    showsLickList(){
      this.showsLickLists=!this.showsLickLists
    },
    showsLicksSmallList(index){
      const that = this
      let smlist = this.nav
      this.ids = index
      smlist[index].show=!this.nav[index].show
      switch (index) {
         case 0:
           this.$router.push({path:'/'})
           break;
        case 1:
        window.scrollTo(0, 200);
        break;
        case 2:
            window.scrollTo(0, 1600);
           break;
        case 3:
           window.scrollTo(0, 2350);
          break;
          case 4:
           window.scrollTo(0, 5350);
          break;
       }
    },
    listenerFunction(e) {
        document.addEventListener('scroll', this.handleScroll, true);
    },
    handleScroll () {
      if(window.pageYOffset!=0){
        this.scrolls = false
      }
        this.scroll = window.pageYOffset
        this.$emit('scroll',this.scroll)
    },
    jumpWeb(index){
       switch (index) {
         case 0:
           this.$router.push({name:'Home'});
           break;
        case 1:
        window.scrollTo(0, 550);
        break;
        case 2:
            window.scrollTo(0, 1450);
           break;
        case 3:
           window.scrollTo(0, 2700);
          break;
        case 4:
           window.scrollTo(0, 5350);
        break;
       }
    }
  },
   mounted() {
      this.listenerFunction();
      const that = this
      that.width = document.body.clientWidth
      that.screewidth(that.width)
      window.onresize= () => {
          return (() => {
              window.screenWidth = document.body.clientWidth;
              that.screenWidth = window.screenWidth;
              
          })();
      }
  },
};
</script>
<style scoped lang="less">
.scroll{
  animation: smimage 0.7s linear
}
@keyframes smimage {
  0%{top: -80px;}
  100%{top:  0;}
}
.header{
    background-color: #fff;
    width: 100%;
    height: 80px;
    z-index: 99;
    position: fixed;
    left: 0;
    top: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    box-shadow: 0 2px 5px rgb(16 16 16 / 8%);
}
.slickmenu{
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 78px;
}
.slickmenu .slicknav_nav{
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  background-color: #fff;
  font-size: 16px;
  color: #263b5e;
  text-align: left;
}

.slickmenu ul li a{
  display: block;
  padding: 10px 15px;
  margin: 2px 5px;
  color: #263b5e;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.2px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
.slickmenu ul li .a_out ul{
      width: 100%;
}
.slickmenu ul li .style{
      color: #999;
}


.but{
  position: relative;
  display: block;
  float: right;
  padding: .438em .625em;
  line-height: 1.125em;
  margin-left: 40px;
  margin-bottom: 5px;
}
.but span{
  display: block;
    width: 25px;
    height: 2px;
    margin-top: 6px;
    background: rgb(37, 37, 37);
}

.nav .logo{
  width:145px;
  height: 45px;
}
.nav .logo img{
  width:145px;
  height: 45px;
}
.nav .logo a{
  display: block;
  width:145px;
  height: 45px;
  display: flex;
  align-items: center;
}
.nav .menu{
  height: 80px;
  display: flex;
  justify-content: center;
}
.nav .menu .menu_l{
  width: 100%;
}
.nav .menu .menu_l ul{
  margin: 0;
  padding: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav .menu .menu_l>ul>li{
  height: 80px;
  padding: 0 40px;
  list-style: none;
  font-family: 'Work Sans', sans-serif;
  font-size: 17px;
  font-weight: 1000;
  position: relative;
}
.nav .menu .menu_l>ul>li:hover ul{
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.nav .menu .menu_l>ul>li>ul>li:hover{
  color: white;
  background: #622dfa;
}
.nav .menu .menu_l>ul>li>ul{
    background-color: #fff;
    width: 200px;
    height: auto;
    position: absolute;
    left: 0;
    top: 180%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease 0s;
    
}
.nav .menu .menu_l ul>li>ul li{
  border-bottom: 2px solid #eee;
    font-size: 12px !important;
}
.nav .menu .menu_l ul>li>ul li{
    width: 200px;
    height: 47px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #eee;
    color: #6a8695;
}
.nav .menu .menu_l ul>li>a{
  text-decoration: none;
  color: #263b5e;
  display: block;
  display: flex;
  align-items: center;
  height: 80px;
  // color: #622dfa 紫
  
}
.nav .menu .menu_r{
  height: 80px;
  display: flex;
  align-items: center;
}
.nav .menu .menu_r .icon{
    font-size: 18px;
    font-family: "Poppins",sans-serif;
    color: #263b5e;
    font-weight: 600;
    line-height: 80px;
    margin-left: 10px;
    position: relative;
    cursor: pointer;
}
.nav .menu .menu_r .icon .counts{
    background-color: #622dfa;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 20px;
    font-size: 10px;
    right: -10px;
}
.nav .menu .menu_r .icon i{
  color: #622dfa;
  font-size: 21px;
}


</style>
